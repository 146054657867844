<template>
<div class="ac-wrap" :class="wrapperClass_">
  <input :id="inputUID" type="text" class="form-control"
    :class="inputClass_"
    :value="value"
    :placeholder="placeholder"
    @input="updateValue($event.target.value)"
    @blur="onBlur"
    @keydown="onKeyDown"
    @keyup="onKeyUp"
    @keypress="onKeyPress"
    @compositionstart="onCompositionStart"
    @compositionend="onCompositionEnd"
    @keydown.up.prevent="onUp"
    @keydown.down.prevent="onDown">
  <ul class="dropdown-menu" :class="{open: open}">
    <li v-for="(suggestion, index) in matches" :key="index"
        :class="{'active': isActive(index)}"
        @mousedown="suggestionMouseDown(index)">
      <a>{{suggestion[suggestionDispProp]}}</a>
    </li>
  </ul>
</div>
</template>

<script>
export default {
  name: 'auto-complete',
  props: {
    value: {
      type: String,
    },
    suggestions: {
      type: Array,
      default() {
        return []
      }
    },
    wrapperClass: {
      type: [Object, Array, String],
      default() {
        return {}
      }
    },
    inputClass: {
      type: [Object, Array, String],
      default() {
        return {}
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    suggestionSearchProp: {
      type: String,
      default: 'val'
    },
    suggestionDispProp: {
      type: String,
      default: 'val'
    },
  },
  data() {
    const KEY_DOWN = 'KEY_DOWN'
    const KEY_PRESS = 'KEY_PRESS'
    const KEY_UP = 'KEY_UP'
    return {
      open: false,
      current: 0,
      // eslint-disable-next-line no-irregular-whitespace
      myReg: new RegExp(`[ 　。、]([^ 　。、]*)$`),
      isComposing: false,
      lastKeyEvent: '',
      KEY_EVENT_DOWN: KEY_DOWN,
      KEY_EVENT_PRESS: KEY_PRESS,
      KEY_EVENT_UP: KEY_UP,
      dummyDataA: 1,
    }
  },
  computed: {
    matches() {
      // hack for forcing recomputation of a computed property,
      // (making this method believe it depends on this value)
      // eslint-disable-next-line no-unused-vars
      const dummy = this.dummyDataA

      let str = this.value || ''
      const elem = document.querySelector('#'+this.inputUID)
      if (elem) {
        const sel = elem.selectionStart
        str = str.slice(0, sel)
      }
      const m = str.match(this.myReg)
      if (m) { str = m[1] }

      return this.suggestions.filter(obj => {
        if (!str) { return true }
        return obj[this.suggestionSearchProp].indexOf(str) !== -1
      })
    },
    inputUID() {
      return `ac-${this._uid}`
    },
    wrapperClass_() {
      const src = this.wrapperClass
      let obj = {}

      if (typeof src === 'string') {
        src.split(/\s+/).forEach(e => {
          obj[e] = true
        })
      } else if (src instanceof Array) {
        src.forEach(e => {
          obj[e] = true
        })
      } else if (src instanceof Object) {
        obj = src
      }
      return Object.assign({}, obj)
    },
    inputClass_() {
      const src = this.inputClass
      let obj = {}
      if (typeof src === 'string') {
        src.split(/\s+/).forEach(e => {
          obj[e] = true
        })
      } else if (src instanceof Array) {
        src.forEach(e => {
          obj[e] = true
        })
      } else if (src instanceof Object) {
        obj = src
      }
      return Object.assign({}, obj)
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    debugLog_(a) {
      //console.log(a)
    },
    openSuggestions() {
      this.open = true
      this.current = 0
    },
    updateValue(value) {
      this.$emit('input', value)
    },
    onBlur() {
      this.open = false
    },
    onCompositionStart($event) {
      this.debugLog_('*** onCompositionStart ***')
      this.debugLog_($event)
      this.isComposing = true
    },
    onCompositionEnd($event) {
      this.debugLog_('*** onCompositionEnd ***')
      this.debugLog_($event)
      this.isComposing = false
      // some browsers (IE, Firefox, Safari) send a keyup event after
      // compositionend, some (Chrome, Edge) don't.

      // some IME input has been fixed.
      this.dummyDataA++
      this.openSuggestions()
      this.debugLog_(this.value)
    },
    onKeyDown() {
      this.lastKeyEvent = this.KEY_EVENT_DOWN
    },
    onKeyPress() {
      this.lastKeyEvent = this.KEY_EVENT_PRESS
    },
    onKeyUp($event) {
      this.debugLog_('*** onKeyUp ***')
      this.debugLog_($event.key)
      if ($event.key === 'Escape') {
        this.open = false

      } else if ($event.key === 'Enter') {
        if (this.isComposing) {
          this.debugLog_('is composing. return.')
          return

        } else {
          // https://garafu.blogspot.jp/2015/09/javascript-ime-enter-event.html
          // when IME is off, events will fire in the order of
          // down -> press -> up
          // when IME is on, it will be
          // down -> up
          this.debugLog_('isEnter')
          this.debugLog_(this.lastKeyEvent)
          if (this.lastKeyEvent !== this.KEY_EVENT_PRESS) {
            // some IME input has been fixed.
            // hack for forcing recomputation of a
            // computed property,
            this.dummyDataA++
            this.openSuggestions()
            this.debugLog_('---> IME input has been fixed')
          } else {
            this.debugLog_('---> not IME input')
            if (this.open) {
              this.applySelectedSuggestion()
            }
          }
        }

      } else {
        if (!this.open) {
          this.openSuggestions()
        }
      }
      this.lastKeyEvent = this.KEY_EVENT_UP
    },
    mergeAutoCompleteVal(val) {
      const elem = document.querySelector('#'+this.inputUID)
      const selStart = elem.selectionStart
      const selEnd = elem.selectionEnd

      let ret
      if (selStart !== selEnd) {
        let str1 = (this.value || '').slice(0, selStart)
        let str2 = (this.value || '').slice(selEnd)
        ret = str1 + val + str2
      } else {
        let str1 = (this.value || '').slice(0, selStart)
        let str2 = (this.value || '').slice(selStart)
        const m = str1.match(this.myReg)
        if (m) {
          let matched = m[1]
          if (matched) {
            matched = matched.replace('(', '\\(').replace(')', '\\)')
            const reg = new RegExp(matched + '$')
            str1 = str1.replace(reg, val)
          } else {
            str1 = str1 + val
          }
        } else {
          str1 = val
        }
        ret = str1 + str2
      }
      return ret
    },
    applySelectedSuggestion() {
      if (this.current >= this.matches.length) { return }
      const selected = this.matches[this.current][this.suggestionDispProp]
      const val = this.mergeAutoCompleteVal(selected)
      this.$emit('input', val)
      this.open = false
    },
    onUp() {
      if (this.open) {
        if (this.current > 0) {
          this.current--
        }
      }
    },
    onDown() {
      if (this.open) {
        if (this.current < this.matches.length - 1) {
          this.current++
        }
      } else {
        this.openSuggestions()
      }
    },
    isActive(index) {
      return index === this.current
    },
    suggestionMouseDown(index) {
      const selected = this.matches[index][this.suggestionDispProp]
      const val = this.mergeAutoCompleteVal(selected)
      this.$emit('input', val)
      this.open = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .ac-wrap {
    position: relative;
  }
  .dropdown-menu {
    font-family: sans-serif;
    font-size: 18px;
    max-height: 300px;
    overflow-y: scroll;
    &.open {
      display: block;
    }
  }
  .ilblk {
    display: inline-block;
  }
  .w94p {
    width: 94%;
  }
</style>
