<template>
  <div id="app">
    <global-header></global-header>
    <router-view></router-view>
    <global-footer></global-footer>
  </div>
</template>

<script>
import axios from 'axios'
import GlobalHeader from '@/components/App/GlobalHeader'
import GlobalFooter from '@/components/App/GlobalFooter'
import routeMixin from '@/mixin/routeMixin'
import masterMixin from '@/mixin/masterMixin'
import * as apiConsts from '@/consts/api.js'

export default {
  name: 'app',
  created() {
    this.setAxios()
    this.loadMasters()

    if (!this.isGuestPage()) {
      this.$store.dispatch('user/getMe')
        .then(() => { /* nothing to do */ })
        .catch(() => {
          this.$router.push({name: 'login'})
        })
    }
  },
  mixins: [routeMixin, masterMixin],
  methods: {
    setAxios() {
      let apiBaseURL = ''
      if (process.env.VUE_API_HOST) {
        const proto = process.env.VUE_API_PROTO
        const host = process.env.VUE_API_HOST
        const port = process.env.VUE_API_PORT
        apiBaseURL = `${proto}://${host}:${port}`
      }
      apiBaseURL += apiConsts.baseURL
      // base url
      axios.defaults.baseURL = apiBaseURL

      // token
      const token = this.$store.getters['localStorage/serverToken']
      if (token) {
        axios.defaults.headers['Authorization'] = `Bearer ${token}`
      }

      // go to login if 401 is returned on responst
      axios.interceptors.response.use(
        response => {
          return response
        },
        error => {
          const st = error.response.status
          if (st === 401 && !this.isGuestPage()) {
            setTimeout(() => {
              location.href = '/login'
            }, 0)
          }
          return Promise.reject(error)
        }
      )
    },
  },
  components: { GlobalHeader, GlobalFooter }
}
</script>

<style lang="scss">
  @use '../../style/app.scss';
  @import '../../../node_modules/quill/dist/quill.snow.css';
  @import '../../../node_modules/quill/dist/quill.bubble.css';
</style>
