var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-header-bar",class:{
      'top-bar': _vm.isHeaderMode,
      'bottom-bar': _vm.isFooterMode,
    }},[(_vm.isFooterMode)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasError),expression:"hasError"}],staticClass:"err-msg err-msg-bottom ta-left"},_vm._l((_vm.errorMessages),function(msg,idx){return _c('div',{key:idx},[_vm._v("・"+_vm._s(msg))])}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"bar-row"},[(_vm.isDraft)?_c('div',{staticClass:"mode-draft"},[_vm._v("下書き")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row"},[(_vm.isNew)?[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"ta-center"},[_c('button',{staticClass:"btn btn-lg btn-default mr-8",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("\n              戻る\n            ")]),_vm._v(" "),_c('button',{staticClass:"btn btn-lg btn-info mr-8",on:{"click":_vm.onClickCreateAsDraft}},[_vm._v("\n              下書き保存\n            ")]),_vm._v(" "),_c('button',{staticClass:"btn btn-lg btn-primary",on:{"click":_vm.onClickCreate}},[_vm._v("\n              保存\n            ")]),_vm._v(" "),_c('div',{staticClass:"memo-about-photo1"},[_vm._v("\n              写真は一度報告書を保存した後に追加できます\n            ")])])])]:(_vm.isDraft)?[_c('div',{staticClass:"col-sm-3"}),_vm._v(" "),_c('div',{staticClass:"col-sm-5"},[_c('div',{staticClass:"ta-center"},[_c('button',{staticClass:"btn btn-lg btn-default mr-8",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("\n              戻る\n            ")]),_vm._v(" "),_c('button',{staticClass:"btn btn-lg btn-info mr-8",on:{"click":_vm.onClickSaveAsDraft}},[_vm._v("\n              下書き保存\n            ")]),_vm._v(" "),_c('button',{staticClass:"btn btn-lg btn-primary",on:{"click":_vm.onClickSave}},[_vm._v("\n              保存\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"col-sm-4"},[_c('router-link',{staticClass:"btn btn-lg btn-default pull-right",attrs:{"to":{
            name: _vm.photoPageRouteName,
            params: {id: _vm.id},
          }}},[_c('i',{staticClass:"fa fa-photo"}),_vm._v("\n            写真\n            "),(_vm.photoNum > 0)?_c('span',{staticClass:"badge badge-num"},[_vm._v("\n              "+_vm._s(_vm.photoNum)+"\n            ")]):_vm._e()]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-lg btn-default pull-right",staticStyle:{"margin-right":"8px"},attrs:{"to":{
            name: _vm.DocumentPageRouteName,
            params: {id: _vm.id},
          }}},[_c('i',{staticClass:"fa fa-file"}),_vm._v("\n            書類\n            "),(_vm.documentNum > 0)?_c('span',{staticClass:"badge badge-num"},[_vm._v("\n              "+_vm._s(_vm.documentNum)+"\n            ")]):_vm._e()])],1)]:[_c('div',{staticClass:"col-sm-3"},[_c('router-link',{staticClass:"btn btn-lg btn-default",attrs:{"to":{
              name:'inspectionReportCorrectionReportList',
              params: {inspection_report_id: _vm.id},
            }}},[_vm._v("\n              指摘事項報告書\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"col-sm-5"},[_c('div',{staticClass:"ta-center"},[_c('button',{staticClass:"btn btn-lg btn-default mr-8",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("\n              戻る\n            ")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-lg btn-default mr-8",class:{ disabled: _vm.isEdited },attrs:{"to":{
                name: _vm.printPageRouteName,
                query: {
                  id: _vm.id,
                  mode: 'print_preview',
                },
              },"target":"_blank"}},[_c('i',{staticClass:"fa fa-print"}),_vm._v("\n              プレビュー\n            ")]),_vm._v(" "),_c('button',{staticClass:"btn btn-lg btn-primary",on:{"click":_vm.onClickSave}},[_vm._v("\n              保存\n            ")])],1)]),_vm._v(" "),_c('div',{staticClass:"col-sm-4"},[_c('router-link',{staticClass:"btn btn-lg btn-default pull-right",attrs:{"to":{
            name: _vm.photoPageRouteName,
            params: {id: _vm.id},
          }}},[_c('i',{staticClass:"fa fa-photo"}),_vm._v("\n            写真\n            "),(_vm.photoNum > 0)?_c('span',{staticClass:"badge badge-num"},[_vm._v("\n              "+_vm._s(_vm.photoNum)+"\n            ")]):_vm._e()]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-lg btn-default pull-right",staticStyle:{"margin-right":"8px"},attrs:{"to":{
            name: _vm.DocumentPageRouteName,
            params: {id: _vm.id},
          }}},[_c('i',{staticClass:"fa fa-file"}),_vm._v("\n            書類\n            "),(_vm.documentNum > 0)?_c('span',{staticClass:"badge badge-num"},[_vm._v("\n              "+_vm._s(_vm.documentNum)+"\n            ")]):_vm._e()])],1)]],2),_vm._v(" "),(_vm.isNew || _vm.isDraft)?_c('div',{staticClass:"row"},[_vm._m(0)]):_vm._e()]),_vm._v(" "),(_vm.isHeaderMode)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasError),expression:"hasError"}],staticClass:"err-msg err-msg-top ta-left"},_vm._l((_vm.errorMessages),function(msg,idx){return _c('div',{key:idx},[_vm._v("・"+_vm._s(msg))])}),0):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"memo-about-draft"},[_vm._v("\n          「下書き保存」された報告書は、作成から7日以上経過すると自動的に削除されます。\n        ")])])}]

export { render, staticRenderFns }