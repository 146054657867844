<template src="./template.html"></template>

<script>
import Vue from 'vue'
import inspectReportApi from '@/api/inspectionReport'

export default {
  name: 'printInspectionReportPhotos',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      report: {},
      photos: [],
      photoMap: [],
      pages: [],
      maxRows: 3,
      api: inspectReportApi,
    }
  },
  mounted() {
    this.addGlobalPageStyle('A4 portrait')
    this.getData()
  },
  methods: {
    addGlobalPageStyle(sizeStr) {
      const styleNode = document.createElement('style')
      styleNode.appendChild(document.createTextNode(`@page { size: ${sizeStr}; }`))
      document.querySelector('head').appendChild(styleNode)
    },
    getData() {
      return this.api.show({id: this.id})
        .then(({ data }) => {
          data.photos.forEach(photo => {
            if (photo.used_index !== null) {
              photo.idx = photo.used_index
            }
            photo.orig_caption = photo.caption
          })
          data.inspection_date = Vue.filter('dtFormatYMDA1')(data.inspection_date)
          this.report = data

          this.photos = data.photos
          this.photoMap = this.photos.reduce((obj, photo) => {
            obj[photo.id] = photo; return obj
          }, {})
          this.pages = this.getPages()
        })
    },
    getEmptyPage(idx) {
      return {
        idx: idx,
        is_empty: true,
        caption: '',
        orig_caption: '',
      }
    },
    getPages() {
      const ret = []
      const usedPhotoIds = this.photos.reduce((arr, photo) => {
        if (photo.used_index !== null) {
          arr[photo.used_index] = photo.id
        }
        return arr
      }, [])
      const len = usedPhotoIds.length
      if (len === 0) {
        ret.push({photos: [
          this.getEmptyPage(0), this.getEmptyPage(1),
        ]})
        return ret
      }

      const halfLen = Math.ceil(len / this.maxRows)
      for (let i = 0; i < halfLen; i++) {
        const idxBase = i * this.maxRows
        const photos = []

        for (let index = 0; index < this.maxRows; index++) {
          let photo = (idxBase + index < len) ? this.photoMap[usedPhotoIds[idxBase + index]] : null
          if (!photo) {
            photo = this.getEmptyPage(idxBase)
          }
          photos.push(photo)
        }
        ret.push({photos: photos})
      }
      return ret
    },
    showPrintDialog() {
      window.print()
    }
  },
}
</script>

<style lang="scss" src="@/style/print.scss" scoped></style>
<style lang="scss" src="./style.scss" scoped></style>
