<template src="./template.html"></template>

<script>
import Vue from 'vue'
import inspectionReportApi from '@/api/inspectionReport'
import { mapState } from 'vuex'
import listPageMixin from '@/mixin/listPageMixin'
import dateMixin from '@/mixin/dateMixin'
import advancedSearchMixin from '@/mixin/advancedSearchMixin'
import mediaMixin from '@/mixin/mediaMixin'
import masterMixin from '@/mixin/masterMixin'
import userApi from '@/api/user'
import { validateMonthRange } from '@/lib/dateHelper';
import { getInitCondition } from '@/lib/advancedSearchHelper';
import { isInspectionReportEditable } from '@/lib/reportHelper';

export default {
  name: 'inspection_report',
  data() {
    return {
      search: {
        dt_from: '',
        dt_to: '',
        office_id: '',
        construction_id: '',
        isCreatedByMe: true,
        is_draft: null,
      },
      advancedSearch: {
        conditions: [],
        fieldInfo: null,
        options: {},
      },
      showAdvancedSearchForm: false,
      hasInputError: false,
      offices: [],
      orig_constructions: [],
      constructions: [],
      save_statuses: [],
      list: [],
      errors: [],
      listItemsPerPage: 20,
      previousPage: null,
      currentPage: 1,
      isSearchResultEmpty: false,
      printMaxCopies: 500,
      reportToDelete: null,
    }
  },
  computed: {
    ...mapState('user', {
      user: state => state,
      has_role_admin: state => state.has_role_admin,
      has_role_shunin: state => state.has_role_shunin,
      has_role_sagyou: state => state.has_role_sagyou,
    }),
    listItemCount() {
      return this.list.length
    },
    filteredList() {
      const startIdx =
        this.listItemsPerPage * Math.max((this.currentPage - 1), 0)
      return this.list.slice(startIdx, startIdx + this.listItemsPerPage)
    },
    showConfirmDeleteModal() {
      return this.reportToDelete !== null
    },
  },
  mounted() {
    this.waitForUserAndMasters().then(() => {
      this.prepareMasters()
      this.prepareSearchParams()
      this.doSearch()
    })
  },
  mixins: [dateMixin, listPageMixin, advancedSearchMixin, mediaMixin, masterMixin],
  methods: {
    prepareMasters() {
      let offices = window.master.offices
      if (this.user.is_sagyou) {
        // 作業者の場合は自事務所のみ
        offices = offices.filter(e => e.id === this.user.office_id)
      }
      this.offices = offices
      this.orig_constructions = window.master.constructions
      this.constructions = this.orig_constructions
      this.save_statuses = this.getListSearchOptionsForSaveStatus()
      const options = this.advancedSearch.options
      const lovs = window.master.lovs
      options.offices = offices
      options.constructions = window.master.constructions
      options.construction_kinds = lovs.construction_kind.vals_inuse
      options.inspection_results = lovs.inspection_result.vals_inuse
      options.climates = lovs.climate.vals_inuse
      options.save_statuses = this.save_statuses
    },
    prepareSearchParams() {
      let dtFrom, dtTo, officeId, constructionId, isCreatedByMe, isDraft

      const listSearchParams = this.getListSearchParamsForTheMonth()
      if (listSearchParams.dtStrFrom) {
        dtFrom = listSearchParams.dtStrFrom
      }
      if (listSearchParams.dtStrTo) {
        dtTo = listSearchParams.dtStrTo
      }

      const storage = JSON.parse(sessionStorage.getItem(this.$route.name)) || {
        dtFrom: null,
        dtTo: null,
        officeId: this.user.office_id,
        construcionId: null,
        isCreatedByMe: true,
        isDraft: null,
      }

      if (storage.dtFrom) {
        dtFrom = storage.dtFrom
      }
      if (storage.dtTo) {
        dtTo = storage.dtTo
      }
      if (storage.officeId) {
        officeId = storage.officeId
      }
      if (storage.constructionId) {
        constructionId = storage.constructionId
      }

      if (Object.hasOwn(storage, 'isCreatedByMe')) {
        isCreatedByMe = storage.isCreatedByMe
      }

      if (Object.hasOwn(storage, 'isDraft')) {
        isDraft = storage.isDraft
      }

      if (this.user.is_sagyou) {
        officeId = this.user.office_id
      }

      if (storage.advancedSearch) {
        this.showAdvancedSearchForm = true
        this.advancedSearch = storage.advancedSearch
      }

      if (storage.previousPage) {
        this.previousPage = storage.previousPage
      }

      this.search = {
        dt_from: dtFrom,
        dt_to: dtTo,
        office_id: officeId,
        construction_id: constructionId,
        isCreatedByMe: isCreatedByMe,
        is_draft: isDraft,
      }

      this.onDateInput()
    },
    filterConstruction() {
      const dtFromParam = this.search.dt_from
      const dtToParam = this.search.dt_to
      const officeIdParam = this.search.office_id
      const constructionIdParam = this.search.construction_id

      // 未選択の場合は全て表示
      if (!dtFromParam && !dtToParam && !officeIdParam) {
        this.constructions = this.orig_constructions
        return
      }

      // 工事件名絞り込み
      const dtFormat = Vue.filter('dtFormat')
      const dtTo = parseInt(dtFormat(dtToParam, 'yyyymmdd'))
      const dtFrom = parseInt(dtFormat(dtFromParam, 'yyyymmdd'))
      this.constructions = this.orig_constructions.filter(e => {
        const useStartDate = parseInt(dtFormat(e.use_start_date, 'yyyymmdd'))
        const useEndDate = parseInt(dtFormat(e.use_end_date, 'yyyymmdd'))
        const isDateValid = (useStartDate <= dtTo && useEndDate >= dtFrom)
        const isOfficeIdValid = !officeIdParam || (e.office_id === officeIdParam)
        return isDateValid && isOfficeIdValid
      })

      if (!this.constructions.find(e => e.id === constructionIdParam)) {
        this.search.construction_id = ''
      }
    },
    isEditable(report) {
      return isInspectionReportEditable(report, this.user);
    },
    saveStatusDisp(isDraft) {
      const saveStatus = this.save_statuses.find(e => e.key === isDraft)
      return saveStatus ? saveStatus.val : ''
    },
    onDateInput() {
      this.hasInputError = false
      if (!this.isDateString(this.search.dt_from)) {
        this.hasInputError = true
      }
      if (!this.isDateString(this.search.dt_to)) {
        this.hasInputError = true
      }

      this.hasInputError = !validateMonthRange(this.search.dt_from, this.search.dt_to, 6)

      // 工事件名絞り込み
      this.filterConstruction()
    },
    async toggleAdvancedSearchForm() {
      if (!this.advancedSearch.fieldInfo) {
        this.advancedSearch.fieldInfo = this.getAdvancedSearchFormInfo('inspection_report')
      }
      if (!this.advancedSearch.options.inspectors) {
        await this.getAdvancedSearchInspectorOptions()
      }
      this.showAdvancedSearchForm = !this.showAdvancedSearchForm

      if (this.advancedSearch.conditions.length > 0) return
      this.advancedSearch.conditions = [getInitCondition(this.user.office_id)];
    },
    async getAdvancedSearchInspectorOptions() {
      const { data } = this.has_role_admin ? await userApi.getAll() : await userApi.getMyOfficeUser()
      this.advancedSearch.options.inspectors = data.filter(user => {
        const today = new Date(new Date().setHours(0, 0, 0, 0))
        const dateStrToDate = Vue.filter('ensureDate')
        const useStartDate = dateStrToDate(user.use_start_date)
        const useEndDate = dateStrToDate(user.use_end_date)
        return useStartDate <= today && today <= useEndDate
      })
    },
    addAdvancedSearchCondition(condition) {
      this.advancedSearch.conditions.push(condition)
    },
    removeAdvancedSearchCondition(idx) {
      this.advancedSearch.conditions.splice(idx, 1)
    },
    doSearch() {
      if (this.hasInputError) { return }
      this._doSearch()
    },
    _doSearch() {
      const storage = {
        dtFrom: this.search.dt_from,
        dtTo: this.search.dt_to,
        officeId: this.search.office_id,
        constructionId: this.search.construction_id,
        isCreatedByMe: this.search.isCreatedByMe,
        isDraft: this.search.is_draft,
      }
      if (this.showAdvancedSearchForm) {
        storage.advancedSearch = this.advancedSearch
      }
      sessionStorage.setItem(this.$route.name, JSON.stringify(storage))

      this.isSearchResultEmpty = false
      this.list = []
      this.setPage(1)

      const obj = {
        from: new Date(this.search.dt_from),
        to: new Date(this.search.dt_to),
        office_id: this.search.office_id,
        construction_id: this.search.construction_id,
        inspector_id: this.search.isCreatedByMe ? this.user.id : '',
        is_draft: this.search.is_draft,
      }
      if (this.showAdvancedSearchForm) {
        obj.advanced_search_conditions = this.advancedSearch.conditions
      }
      inspectionReportApi.search(obj).then(({ data }) => {
        if (!data || data.length === 0) {
          this.isSearchResultEmpty = true
          return
        }
        this.list = data
        if (this.previousPage) {
          this.setPage(this.previousPage)
          this.previousPage = null
        }
      })
    },
    downloadCsv() {
      inspectionReportApi.downloadCsv({ ids: this.list.map(e => e.id) })
        .then(res => {
          const { url, filename } = this.toBlobUrl(res)
          const link = document.createElement('a')
          link.href = url
          link.download = filename
          link.click()
        })
    },
    downloadIdentityManagement() {
      inspectionReportApi.downloadIdentityManagement({ ids: this.list.map(e => e.id) })
        .then(res => {
          const { url, filename } = this.toBlobUrl(res)
          const link = document.createElement('a')
          link.href = url
          link.download = filename
          link.click()
        })
    },
    confirmDeleteReport(e) {
      this.errors = {}
      this.reportToDelete = e
    },
    deleteReport() {
      const obj = {
        id: this.reportToDelete.id,
      }
      inspectionReportApi.delete(obj)
        .then(() => {
          this.reportToDelete = null
          this._doSearch()
        }).catch(err => {
          this.errors = err.response.data
        })
    },
    setPage(evt) {
      this.currentPage = evt
      const storage = JSON.parse(sessionStorage.getItem(this.$route.name))
      storage.previousPage = this.currentPage
      sessionStorage.setItem(this.$route.name, JSON.stringify(storage))
    },
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
