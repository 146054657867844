import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior(/* to, from, savedPosition */) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'top',
      component: require('@/components/Top').default
    },
    {
      path: '/login',
      name: 'login',
      component: require('@/components/Login').default
    },
    {
      path: '/two_factor_auth',
      name: 'twoFactorAuth',
      component: require('@/components/TwoFactorAuth').default
    },
    // 工事件名
    {
      path: '/construction',
      name: 'constructionList',
      component: require('@/components/construction/ConstructionList').default
    },
    {
      path: '/construction/new',
      name: 'constructionNew',
      component: require('@/components/construction/ConstructionDetail').default
    },
    {
      path: '/construction/:id',
      name: 'constructionDetail',
      component: require('@/components/construction/ConstructionDetail').default,
      props: true,
    },
    // 事務所名
    {
      path: '/office',
      name: 'officeList',
      component: require('@/components/office/OfficeList').default
    },
    {
      path: '/office/new',
      name: 'officeNew',
      component: require('@/components/office/OfficeDetail').default
    },
    {
      path: '/office/:id',
      name: 'officeDetail',
      component: require('@/components/office/OfficeDetail').default,
      props: true,
    },
    // 写真
    {
      path: '/photo',
      name: 'photoList',
      component: require('@/components/photo/PhotoList').default
    },
    // 検査報告書
    {
      path: '/inspection_report',
      name: 'inspectionReportList',
      component: require('@/components/inspectionReport/InspectionReportList').default
    },
    {
      path: '/inspection_report/new',
      name: 'inspectionReportNew',
      component: require('@/components/inspectionReport/InspectionReportDetail').default
    },
    {
      path: '/inspection_report/:id',
      name: 'inspectionReportDetail',
      component: require('@/components/inspectionReport/InspectionReportDetail').default,
      props: true,
    },
    {
      path: '/inspection_report/:id/copy',
      name: 'inspectionReportDetailCopy',
      component: require('@/components/inspectionReport/InspectionReportDetail').default,
      props: true,
    },
    {
      path: '/inspection_report_detail/:id/photos',
      name: 'inspectionReportPhotos',
      component: require('@/components/inspectionReport/InspectionReportPhotos').default,
      props: true,
    },
    {
      path: '/inspection_report_detail/:id/inspection_content_photos',
      name: 'inspectionReportContentPhotos',
      component: require('@/components/inspectionReport/InspectionReportContentPhotos').default,
      props: true,
    },
    {
      path: '/inspection_report_detail/:id/inspection_result_content_photos',
      name: 'inspectionReportResultContentPhotos',
      component: require('@/components/inspectionReport/InspectionReportResultContentPhotos').default,
      props: true,
    },
    {
      path: '/inspection_report_detail/:id/documents',
      name: 'inspectionReportDocuments',
      component: require('@/components/inspectionReport/InspectionReportDocuments').default,
      props: true,
    },
    {
      path: '/print/inspection_reports',
      name: 'printInspectionReports',
      component: require('@/components/inspectionReport/PrintInspectionReport').default,
      props: route => route.query,
    },
    {
      path: '/print/inspection_report/:id/photos',
      name: 'printInspectionReportPhotos',
      component: require('@/components/inspectionReport/PrintInspectionReportPhotos').default,
      props: true,
    },
    // 立会検査等における指摘事項報告
    {
      path: '/correction_report',
      name: 'correctionReportList',
      component: require('@/components/correctionReport/CorrectionReportList').default
    },
    {
      path: '/inspection_report/:inspection_report_id/correction_report',
      name: 'inspectionReportCorrectionReportList',
      component: require('@/components/correctionReport/CorrectionReportList').default
    },
    {
      path: '/inspection_report/:inspection_report_id/correction_report/new',
      name: 'correctionReportNew',
      component: require('@/components/correctionReport/CorrectionReportDetail').default
    },
    {
      path: '/inspection_report/:inspection_report_id/correction_report/:id',
      name: 'correctionReportDetail',
      component: require('@/components/correctionReport/CorrectionReportDetail').default,
      props: true,
    },
    {
      path: '/inspection_report/:inspection_report_id/correction_report_detail/:id/correction_content_before_photos',
      name: 'correctionReportCorrectionBeforePhotos',
      component: require('@/components/correctionReport/CorrectionReportContentBeforePhotos').default,
      props: true,
    },
    {
      path: '/inspection_report/:inspection_report_id/correction_report_detail/:id/correction_content_after_photos',
      name: 'correctionReportCorrectionAfterPhotos',
      component: require('@/components/correctionReport/CorrectionReportContentAfterPhotos').default,
      props: true,
    },
    {
      path: '/print/correction_reports',
      name: 'printCorrectionReports',
      component: require('@/components/correctionReport/PrintCorrectionReport').default,
      props: route => route.query,
    },
    // 業務報告書
    {
      path: '/work_summary_report_calendar',
      name: 'workSummaryReportCalendar',
      component: require('@/components/workSummaryReport/WorkSummaryReportCalendar').default
    },
    {
      path: '/offices/:office_id/work_summary_report_detail/:date',
      name: 'workSummaryReportDetailDate',
      component: require('@/components/workSummaryReport/WorkSummaryReportDetail').default
    },
    {
      path: '/offices/:office_id/print/patterns/:pattern_id/work_summary_report_details',
      name: 'printWorkSummaryReportDetails',
      component: require('@/components/workSummaryReport/PrintWorkSummaryReportDetail').default,
      props: route => Object.assign({}, route.params, route.query),
    },
    {
      path: '/work_summary_report_entry_list',
      name: 'workSummaryReportEntryList',
      component: require('@/components/workSummaryReport/WorkSummaryReportEntryList').default,
    },

    {
      path: '/masters',
      name: 'masterList',
      component: require('@/components/master/MasterList').default,
    },
    {
      path: '/masters/inside_work_content',
      name: 'masterDetailInsideWorkContent',
      component: require('@/components/master/MasterDetailInsideWorkContent').default,
      props: true,
    },
    {
      path: '/masters/:id',
      name: 'masterDetail',
      component: require('@/components/master/MasterDetail').default,
      props: true,
    },
    {
      path: '/users',
      name: 'userList',
      component: require('@/components/user/UserList').default,
      props: true,
    },
    // catch all
    {
      path: '*',
      redirect: {name: 'login'},
    },
  ]
})
