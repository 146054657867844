<template>
<div class="container">
  <div class="page page-top">
    <div class="ibox float-e-margins ibox1">
      <div class="ibox-content clearfix">
        <p class="dt">
          <span>{{ dt | dtFormatYMDA1 }}</span>
        </p>
      </div>
    </div>

    <div class="ibox float-e-margins ibox2">
      <div class="ibox-content clearfix">
        <div class="col-md-12">
          <div class="col-md-3">
            <router-link :to="{name:'inspectionReportList'}" class="btn btn-lg btn-default">
              <span>立会検査報告書</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'correctionReportList'}" class="btn btn-lg btn-default">
              <span>指摘事項報告書</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'workSummaryReportCalendar'}" class="btn btn-lg btn-default">
              <span>業務状況報告書</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'photoList'}" class="btn btn-lg btn-default">
              <span>写真台帳</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="ibox float-e-margins ibox3">
      <div class="ibox-content clearfix">

        <div class="col-md-12 row2">
          <div class="col-md-3">
            <router-link :to="{name:'constructionList'}" class="btn btn-lg btn-default">
              <span>工事件名登録</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'officeList'}" class="btn btn-lg btn-default" v-if="has_role_shunin">
              <span>事務所登録</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'masterList'}" class="btn btn-lg btn-default" v-if="has_role_admin">
              <span>その他マスタ登録</span>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{name:'userList'}" class="btn btn-lg btn-default" v-if="has_role_admin">
              <span>ユーザー管理</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="ibox float-e-margins ibox4">
      <div class="ibox-content clearfix">
        <div class="col-md-12 row1">
          <div class="system-manual">
            <a class="btn btn-link" v-if="is_admin"
                href="/static/pdf/報告書作成システム操作マニュアル(管理者用).pdf"
                download>
              <i class="fa fa-download"></i>
              操作マニュアル(管理者用)ダウンロード
            </a>
            <a class="btn btn-link"  v-if="is_soukatsu || is_shunin"
                href="/static/pdf/報告書作成システム操作マニュアル(主任・総括用).pdf"
                download>
              <i class="fa fa-download"></i>
              操作マニュアル(主任・総括用)ダウンロード
            </a>
            <a class="btn btn-link"  v-if="is_sagyou"
                href="/static/pdf/報告書作成システム操作マニュアル(作業者用).pdf"
                download>
              <i class="fa fa-download"></i>
              操作マニュアル(作業者用)ダウンロード
            </a>
          </div>
        </div>
        <div class="col-md-12 row2" v-if="false && is_admin">
          <div class="db-dump">
            <http-link href="/logs/download_db_dump" class="btn btn-link">
              <i class="fa fa-download"></i>
              DBダンプダウンロード
            </http-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import masterMixin from '@/mixin/masterMixin'
import mediaMixin from '@/mixin/mediaMixin'

export default {
  name: 'top',
  data() {
    return {
      dt: new Date(),
    }
  },
  computed: {
    ...mapState('user', {
      is_admin: state => state.is_admin,
      is_soukatsu: state => state.is_soukatsu,
      is_shunin: state => state.is_shunin,
      is_sagyou: state => state.is_sagyou,
      has_role_admin: state => state.has_role_admin,
      has_role_soukatsu: state => state.has_role_soukatsu,
      has_role_shunin: state => state.has_role_shunin,
      has_role_sagyou: state => state.has_role_sagyou,
    }),
  },
  mixins: [masterMixin, mediaMixin],
  mounted() {
    this.waitForUserAndMasters()
  },
}
</script>

<style lang="scss" scoped>
.ibox1 {
  margin-top: 24px;
}

.dt {
  font-size: 18px;
  margin: 0;
}

.btn.btn-lg {
  width: 90%;
  height: 70px;
  margin: 0;

  line-height: 2.8;
  &.two-lines {
    padding-top: 14px;
    line-height: 1.2;
  }
}

.ibox2 .btn {
  color: #ffffff;
  background-color: #348ce2;
  border-color: #3283d3;
}
.ibox3 .btn {
  color: #ffffff;
  background-color: #e25134;
  border-color: #bf442b;
}

.ibox3 .row1 {
  margin-bottom: 16px;
}

.ibox4 .ibox-content {
  padding-bottom: 16px;

  .system-manual, .db-dump {
    margin-left: 4px;
    .btn-link {
      margin: 0;
    }
  }
}

.ibox5 .ibox-content {
  padding: 15px 34px;

  .row2 {
    margin-top: 16px;

    .disabled {
      background-color: #eeeeee;
      .btn-link {
        cursor: default;
        &:hover {
          color: inherit;
        }
      }
    }
  }
}
.bkup-status {
  .tbl {
    display: grid;
    grid-template-columns: auto auto auto auto auto 1fr;
    border: solid 1px #e7eaec;
  }
  .cell {
    display: flex;
    align-items: center;
    border-bottom: solid 1px #e7eaec;
    padding: 10px 0;
  }
  .tbl-row {
    display: contents;
    .col1 {
      border-right: solid 1px #e7eaec;
      padding: 10px;
    }
  }
  .row-type1 {
    .col2 {
      grid-column: 2 / span 5;
      padding-left: 10px;
    }
  }
  .row-type2 {
    .col2 {
      justify-self: end;
      padding-left: 10px;
    }
    .col3 {
      padding: 10px 4px;
    }
    .col4 {
      justify-self: end;
    }
    .col5 {
      padding: 10px
    }
    .col6 {
      display: grid;
      grid-template-rows: auto;
      padding: 10px;
    }
  }
  .success {
    color: #00aa00;
    font-weight: bold;
  }
  .failed {
    color: #e25134;
    font-weight: bold;
  }
}
</style>
