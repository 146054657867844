<template src="./template.html"></template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'
import { FullCalendar } from 'vue-full-calendar'
import 'fullcalendar/dist/locale/ja'
import dateMixin from '@/mixin/dateMixin'
import mediaMixin from '@/mixin/mediaMixin'
import masterMixin from '@/mixin/masterMixin'
import workSummaryReportApi from '@/api/workSummaryReport'
import officeApi from '@/api/office'

export default {
  name: 'workSummaryReportCalendar',
  components: { FullCalendar },
  data() {
    this.today = new Date()
    this.events = []
    this.config = {
      defaultView: 'month',
      views: {
        month: { titleFormat: 'YYYY年 M月' }
      },
      editable: false,
      timezone: 'Asia/Tokyo',
      dayRender(date) {
        const dtFormat = Vue.filter('dtFormat')
        const now = new Date()
        const targetDate = parseInt(dtFormat(date._d, 'yyyymmdd'))
        const today = parseInt(dtFormat(now, 'yyyymmdd'))
        // 年度内は先2週間まで編集可能
        let limitDate = new Date(now.valueOf() + 60 * 60 * 24 * 14 * 1000)
        if (now.getMonth() + 1 === 3 && limitDate.getMonth() + 1 > 3) {
          // 年度をまたぐ場合は年度末日まで
          limitDate = new Date(now.getFullYear(), 3, 0)
        }
        limitDate = parseInt(dtFormat(limitDate, 'yyyymmdd'))
        if (today < targetDate && targetDate <= limitDate) {
          const elems = document.querySelectorAll(`[data-date="${date.format()}"]`)
          for (const el of elems) {
            el.classList.add('editable')
          }
        }
      },
    }
    return {
      office_id: 0,
      dt_from: '',
      dt_to: '',
      pattern_id: 0,
      offices: [],
      reports: [],
      isSearchResultEmpty: false,
      printMaxCopies: 100,

      today: this.today,
      events: this.events,
      config: this.config,
      targetDate: '',

      showConfirmBeforeMoveModal: false,
    }
  },
  mounted() {
    const promises = [
      this.waitForUserAndMasters(),
      this.getOffices()
    ]

    Promise.all(promises).then(() => {
      this.prepareOffice()
      this.doSearch()
    })
  },
  computed: {
    ...mapState('user', {
      user: state => state,
      has_role_admin: state => state.has_role_admin,
      has_role_shunin: state => state.has_role_shunin,
      has_role_sagyou: state => state.has_role_sagyou,
    }),
    office() {
      return this.offices.find(office => {
        return office.id === this.office_id
      })
    },
    hasSearchConditions() {
      if (!this.isDateString(this.dt_from)) {
        return false
      }
      if (!this.isDateString(this.dt_to)) {
        return false
      }
      const dtFormat = Vue.filter('dtFormat')
      const dtStrFrom = dtFormat(this.dt_from, 'yyyy/mm/dd')
      const dtStrTo = dtFormat(this.dt_to, 'yyyy/mm/dd')
      if ((new Date(dtStrTo) - new Date(dtStrFrom)) > (31 - 1) * 24 * 60 * 60 * 1000) {
        return false
      }

      return this.office_id && this.dt_from && this.dt_to
    },
    exceedsPrintMaxCopies() {
      return this.reports.length > this.printMaxCopies
    },
    isPrintDisabled() {
      return !this.hasSearchConditions || this.isSearchResultEmpty || this.exceedsPrintMaxCopies
    },
    isDownloadDisabled() {
      return !this.hasSearchConditions || this.isSearchResultEmpty
    }
  },
  mixins: [dateMixin, masterMixin, mediaMixin],
  methods: {
    async getOffices() {
      const { data } = await officeApi.index()
      this.offices = data
    },
    getSessionStorageItem() {
      return JSON.parse(sessionStorage.getItem(this.$route.name)) || {
        officeId: this.user.is_admin ? 0 : this.user.office_id,
        dtFrom: this.dt_from,
        dtTo: this.dt_to,
        patternId: this.pattern_id,
      }
    },
    prepareOffice() {
      let { officeId, dtFrom, dtTo, patternId } = this.getSessionStorageItem()
      if (this.user.is_sagyou) {
        officeId = this.user.office_id
      }

      this.office_id = officeId
      this.dt_from = dtFrom
      this.dt_to = dtTo
      this.pattern_id = patternId
    },
    doSearch() {
      if (!this.hasSearchConditions) { return }
      this._doSearch()
    },
    _doSearch() {
      sessionStorage.setItem(this.$route.name, JSON.stringify({
        officeId: this.office_id,
        dtFrom: this.dt_from,
        dtTo: this.dt_to,
        patternId: this.pattern_id,
      }))

      this.isSearchResultEmpty = false
      this.reports = []
      const obj = {
        office_id: this.office_id,
        from: new Date(this.dt_from),
        to: new Date(this.dt_to),
      }
      workSummaryReportApi.search(obj).then(({ data }) => {
        if (!data || data.length === 0) {
          this.isSearchResultEmpty = true
          return
        }
        this.reports = data
      })
    },
    onOfficeChange() {
      this.pattern_id = 0
      this.doSearch()
    },
    onPatternIdChange() {
      const storage = this.getSessionStorageItem()
      storage.patternId = this.pattern_id
      sessionStorage.setItem(this.$route.name, JSON.stringify(storage))
    },
    dayClicked(event) {
      if (!this.office_id && this.has_role_shunin) {
        alert('事務所を選択してください。')
        return false
      }

      this.targetDate = event._d
      // 変更時はタイムゾーンに注意
      const dtFormat = Vue.filter('dtFormat')
      const targetDate = parseInt(dtFormat(this.targetDate, 'yyyymmdd'))
      const today = parseInt(dtFormat(this.today, 'yyyymmdd'))

      // 年度内は先2週間まで編集可能
      let limitDate = new Date(this.today.valueOf() + 60 * 60 * 24 * 14 * 1000)
      if (this.today.getMonth() + 1 === 3 && limitDate.getMonth() + 1 > 3) {
        // 年度をまたぐ場合は年度末日まで
        limitDate = new Date(this.today.getFullYear(), 3, 0)
      }
      limitDate = parseInt(dtFormat(limitDate, 'yyyymmdd'))

      if (targetDate <= today) {
        this.goToWorkSummaryReportDetailPage()
      } else if (today < targetDate && targetDate <= limitDate) {
        this.showConfirmBeforeMoveModal = true
      }
    },
    goToWorkSummaryReportDetailPage() {
      const targetDate = Vue.filter('dtFormat')(this.targetDate, 'yyyy-mm-dd')
      window.location.href =
        '/offices/' + this.office_id + '/work_summary_report_detail/' + targetDate
    },
    async downloadCsv() {
      const result = await workSummaryReportApi.downloadCsv({ ids: this.reports.map(e => e.id) })
      const { url, filename } = this.toBlobUrl(result)
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      link.click()
    },
  }
}
</script>

<style lang="scss">
.title-area {
  .title-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    .title, .btn { margin-bottom: 0; }
  }
}
.fc {
  .fc-right {
    width: 130px;
    height: 1px;
    .fc-button-group {
      display: none;
    }
  }
  .fc-future {
    background-color: silver;
    &.editable {
      background-color: #efefef;
      cursor: pointer;
    }
  }
  .fc-today {
    cursor : pointer;
  }
  .fc-past {
    cursor : pointer;
  }
}
</style>
<style>
  @import '../../../../node_modules/fullcalendar/dist/fullcalendar.css';
</style>
