import axios from 'axios'
import * as utils from './apiUtils'

export default {
  search(obj) {
    return axios.get(utils.getApiUrl('/work_summary_reports/search'), {
      params: obj || {}
    })
  },
  show({ officeId, data }) {
    return axios.get(utils.getApiUrl(`/offices/${officeId}/work_summary_reports`), {
      params: data || {}
    })
  },
  update({ officeId, data }) {
    return axios.put(utils.getApiUrl(`/offices/${officeId}/work_summary_reports`), data)
  },
  get_by_ids(ids) {
    return axios.post(utils.getApiUrl('/work_summary_reports/get_by_ids'), ids)
  },
  downloadCsv(ids) {
    return axios.post('/work_summary_reports/download.csv', ids, {
      responseType: 'blob'
    })
  },
}
