<template>
  <div class="page page-otp mx-auto">
    <div class="otp-header row text-left">
      <h2>ワンタイムコードの入力</h2>
    </div>
    <div class="otp-message row">
      <p>
        ワンタイムコードを記載したメールをあなたのEmailアドレスへ送信しました。<br>
        受信できない場合は<a class="resend-link" href="" @click.prevent="resend"><b>こちら</b></a>をクリックしてください。
      </p>
    </div>
    <div v-if="showInfoMessage" class="info-msg text-left">
      <label class="small info">
        {{ infoMessage }}
      </label>
    </div>
    <div v-if="showErrorMessage" class="err-msg text-left">
      <label class="small error">
        {{ errorMessage }}
      </label>
    </div>
    <form class="form-horizontal" @submit.prevent="verify">
      <label class="control-label otp-label">ワンタイムコード</label>
      <div class="otp-body row">
        <div class="form-group">
          <input
            type="text"
            name="otp"
            class="form-control input-lg"
            v-model="otp"
            required="required">
        </div>
      </div>
      <div class="auth-button row">
        <div class="form-group">
            <button type="submit" class="btn btn-primary btn-lg btn-block login-btn">
              ログイン
            </button>
          </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import twoFactorAuthApi from '@/api/twoFactorAuth'
import masterMixin from '@/mixin/masterMixin'
export default {
  name: 'two-factor-auth',
  data() {
    return {
      otp: '',
      twoFactorChallengeToken: '',
      infoMessage: '',
      showInfoMessage: false,
      errorMessage: '',
      showErrorMessage: false,
    }
  },
  created() {
    this.$store.dispatch('user/getMe')
      .then(() => {
        // ログイン認証が完了している(getMeが取れる)場合はトップへ遷移
        this.$router.replace({ name: 'top' })
      })
      .catch(() => {
        this.twoFactorChallengeToken = this.$route.query.challenge_token
        if (!this.twoFactorChallengeToken) {
          // 一時トークンがない場合はログイン画面へ遷移
          this.$router.replace({ name: 'login' })
        }
      })
  },
  methods: {
    async verify() {
      this.resetMessage()
      try {
        const res = await this.$store.dispatch('user/twoFactorAuthVerify', {
          otpCode: this.otp,
          twoFactorChallengeToken: this.twoFactorChallengeToken,
        })
        await this.$store.dispatch('localStorage/set', {
          key: 'serverToken',
          val: res.access_token,
        })
        axios.defaults.headers['Authorization'] = `Bearer ${res.access_token}`
        this.loadMasters()
        this.$router.push({ name: 'top' })
      } catch (err) {
        this.errorMessage = err.response && err.response.data ? err.response.data : 'ワンタイムコードの認証に失敗しました。'
        this.showErrorMessage = true
      }
    },
    async resend() {
      this.resetMessage()
      try {
        await twoFactorAuthApi.resend(this.twoFactorChallengeToken)
        this.infoMessage = 'ワンタイムコードを再送しました。'
        this.showInfoMessage = true
      } catch (err) {
        this.errorMessage = err.response && err.response.data ? err.response.data : 'ワンタイムコードの再送に失敗しました。'
        this.showErrorMessage = true
      }
    },
    resetMessage() {
      this.infoMessage = ''
      this.showInfoMessage = false
      this.errorMessage = ''
      this.showErrorMessage = false
    },
  },
  mixins: [masterMixin],
}
</script>

<style lang="scss" scoped>
.page-otp {
  /* ページの中央に寄せる */
  margin: 0 auto;
  max-width: 600px; /* 必要に応じて調整 */

  .otp-header {
    margin: 60px 0 30px 0;
    color: #000000;
  }
  .otp-message {
    margin: 8px auto 16px;
    text-align: left;
    width: 100%;
    white-space: nowrap;
    font-size: 1.15em;
  }
  .resend-link {
    text-decoration: underline;
  }
  .otp-label {
    font-size: 1.75rem;
    margin: 0 0 8px 0px;
  }
  .otp-body {
    margin: 0px 16px 0 16px;
  }
  .auth-button {
    max-width: 320px;
    margin: 0 16px;
  }
  .info-msg {
    font-size: 18px;
  }
  .info-msg, .err-msg {
    margin: 8px auto;
  }
  .small.error {
    font-size: 0.8em;
  }
  .login-btn {
    background-color: #158cba;
    border-color: #127ba3;
    &:hover {
      background-color: #117298;
      border-color: #106a8c;
    }
  }
}
</style>
