export const isInspectionReportEditable = (report, user) => {
  if (user.is_sagyou) {
    // 作業者は自分が作成した報告書のみ編集可能
    return report.inspectors && report.inspectors.findIndex(inspector => inspector.id === user.id) !== -1
  }
  if (user.is_shunin || user.is_soukatsu) {
    // 主任・総括は自事業所内の報告書のみ編集可能
    return report.inspector_office_id === user.office_id
  }
  if (user.is_admin) {
    // 管理者は全て編集可能
    return true
  }
  return false
};
