import axios from 'axios'
import * as utils from './apiUtils'

export default {
  verify(params) {
    return axios.post(utils.getApiUrl('/users/two_factor_auth/verify'), {
      otp_code: params.otpCode,
      two_factor_challenge_token: params.twoFactorChallengeToken,
    })
  },
  resend(two_factor_challenge_token) {
    return axios.post(utils.getApiUrl('/users/two_factor_auth/resend'), {
      two_factor_challenge_token,
    })
  },
}
