<template>
<textarea class="form-control ib" :style="{width:width}"
  :class="{'bg-blue': !readonly}" v-model="inputValue" :readonly="readonly"
  @change="onChange"></textarea>
</template>

<script>
export default {
  name: 'text-area',
  props: {
    value: {
      type: [String, Number],
    },
    dataType: {
      type: String,
      default: 'string',
      validator(val) {
        return ['string', 'number'].includes(val)
      },
    },
    width: {
      type: String,
      default: '100%',
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      inputValue: this.convVal(this.value),
    }
  },
  watch: {
    value() {
      this.inputValue = this.convVal(this.value)
    },
  },
  methods: {
    formatVal(val) {
      if (val === null || val === undefined) { return val }
      return val
    },
    convVal(val) {
      return this.formatVal(val)
    },
    onChange() {
      this.inputValue = this.convVal(this.inputValue)
      this.$emit('input', this.inputValue)
    }
  },
}
</script>