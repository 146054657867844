<template>
<div class="page page-login">
  <div class="login-header row text-center">
    <div class="logo">
      <h1 style="font-weight: 500;">施工管理報告書システム</h1>
      <h3>Cloud Version</h3>
    </div>
  </div>

  <div class="login-body row center-block">
    <div v-if="showErrorMessage" class="err-msg text-center">
      <label class="small error">
        ログインIDまたはパスワードが間違っています
      </label>
    </div>
    <form class="form-horizontal" @submit.prevent="submitLogin">
      <div class="form-group">
        <input type="text" name="id"
           class="form-control input-lg"
           v-model="loginForm.uid"
           required="required"
           placeholder="ログインID">
      </div>
      <div class="form-group">
        <input type="password" name="password"
           class="form-control input-lg"
           v-model="loginForm.password"
           required="required"
           placeholder="パスワード">
      </div>
      <div class="form-group">
        <button type="submit"
            class="btn btn-primary btn-lg btn-block login-btn">
          ログイン
        </button>
      </div>
    </form>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'login',
  created() {
    this.$store.dispatch('user/getMe')
      .then(() => {
        // redirect to top if already logged in
        this.$router.replace({name: 'top'})
      })
      .catch(() => {
        // nothing to do
      })
  },
  data() {
    return {
      loginForm: {
        uid: '',
        password: '',
      },
      showErrorMessage: false,
    }
  },
  methods: {
    async submitLogin() {
      this.showErrorMessage = false

      // loginしようとするときは明示的に消す.
      // expiredなトークンを送り続けるとログインできないので.
      delete axios.defaults.headers['Authorization']
      try {
        const res = await this.$store.dispatch('user/login', this.loginForm)
        // 二段階認証が有効なユーザーは、一時トークンを使い二段階認証画面で最初の認証結果を保持する
        if (res['two_factor_challenge_token']) {
          const token = encodeURIComponent(res['two_factor_challenge_token']);
          location.href = `/two_factor_auth?challenge_token=${token}`;
        } else {
          await this.$store.dispatch('localStorage/set', {
            key: 'serverToken',
            val: res['access_token'],
          })
          location.href = '/'
        }
      } catch (e) {
        this.showErrorMessage = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .page-login {
    .login-header {
      margin: 60px 0 16px 0;
      color: #000000;
    }
    .login-body {
      max-width: 320px;
      margin: 8px auto 0;
    }
    .small.error {
      font-size: 0.8em;
    }
    .login-btn {
      background-color: #158cba;
      border-color: #127ba3;
      &:hover {
        background-color: #117298;
        border-color: #106a8c;
      }
    }
  }
</style>
