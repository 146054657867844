<template src="./template.html"></template>

<script>
import Vue from 'vue'
import areaApi from '@/api/area'
import userApi from '@/api/user'
import officeApi from '@/api/office'
import errorHandleMixin from '@/mixin/errorHandleMixin'
import mediaMixin from '@/mixin/mediaMixin'
import { MFA_EMAIL } from '@/consts/twoFactorAuth'

export default {
  name: 'userList',
  data() {
    const initialSearchParams = {
      officeId: '',
      userStatus: 'active_only'
    }
    const { officeId, userStatus } =
      JSON.parse(sessionStorage.getItem(this.$route.name)) ||
      initialSearchParams
    return {
      list: [],
      offices: [],
      errors: [],
      csvErrors: [],
      createEntry: null,
      deleteEntry: null,
      editPasswordEntry: null,
      calendarBgColor: 'white',
      filterOfficeIdEdit: officeId,
      filterOfficeId: officeId,
      filterUserStatusEdit: userStatus,
      filterUserStatus: userStatus,
      isUploadingCsv: false,
      showCsvUploadCompleteModal: false,
      showCsvUploadErrorModal: false,
      csvUploadResults: {},
      userListMaxHeight: 2000, // 適当
    }
  },
  computed: {
    filteredList() {
      let filteredList = this.list
      if (this.filterOfficeId !== '') {
        filteredList = filteredList.filter(user => {
          return user.office_id === this.filterOfficeId
        })
      }
      if (this.filterUserStatus !== '') {
        filteredList = filteredList.filter(user => {
          let todayStartTime = (new Date()).setHours(0, 0, 0, 0)
          let useStartTime = (new Date(Date.parse(user.use_start_date))).setHours(0, 0, 0, 0)
          let useEndTime = (new Date(Date.parse(user.use_end_date))).setHours(0, 0, 0, 0)
          return useStartTime <= todayStartTime && todayStartTime <= useEndTime
        })
      }
      return filteredList
    },
    showCreateModal() {
      return !!this.createEntry
    },
    showDeleteConfirmModal() {
      return !!this.deleteEntry
    },
    showEditPasswordModal() {
      return !!this.editPasswordEntry
    },
  },
  mounted() {
    const promises = [
      this.getUsers(),
      this.getOffices()
    ]
    Promise.all(promises).then(() => {
      this.setUserListMaxHeight()
    })
    window.addEventListener('resize', this.setUserListMaxHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.setUserListMaxHeight)
  },
  mixins: [userApi, officeApi, areaApi, errorHandleMixin, mediaMixin],
  methods: {
    setUserListMaxHeight() {
      const windowH = window.innerHeight
      const userListOffsetTop = this.$refs.userList.offsetTop
      const userListOffsetBottom = 20
      this.userListMaxHeight = windowH - userListOffsetTop - userListOffsetBottom
    },
    errorMessages() {
      const acc = []
      Object.keys(this.errors).forEach(k => {
        const msgs = this.errors[k]
        acc.push(...msgs)
      })
      return acc
    },
    csvErrorMessages() {
      const errors = {}
      this.csvErrors.forEach(error => {
        const row = error.row
        Object.keys(error).forEach(k => {
          if (k === 'row') { return }
          if (!errors[row]) { errors[row] = [] }
          const msgs = error[k]
          errors[row].push(...msgs)
        })
      })
      return errors
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    async getUsers() {
      const { data } = await userApi.getAll()
      this.list = data.map(user => this.convUser_(user))
    },
    async getOffices() {
      const { data } = await officeApi.index()
      this.offices = data
    },
    convUser_(data) {
      data.isEditMode = false
      data.use_start_date_input = data.use_start_date
      data.use_end_date_input = data.use_end_date
      data.latest_transfer_date_input = data.latest_transfer_date
      data.editted_password = ''
      return data
    },
    changeFilter() {
      sessionStorage.setItem(this.$route.name, JSON.stringify({
        officeId: this.filterOfficeIdEdit,
        userStatus: this.filterUserStatusEdit,
      }))
      this.filterOfficeId = this.filterOfficeIdEdit
      this.filterUserStatus = this.filterUserStatusEdit
    },
    async downloadCsv() {
      const reqObj = { ids: this.filteredList.map(e => e.id) }
      const blob = await userApi.downloadCsv(reqObj)
      const { url, filename } = this.toBlobUrl(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      link.click()
    },
    async uploadCsv(e) {
      const file = e.target.files[0]
      if (!file) { return }
      this.isUploadingCsv = true
      const formData = new FormData()
      formData.append('csv', file, file.name)
      const reqObj = { formData: formData }

      try {
        const { data } = await userApi.uploadCsv(reqObj)
        this.list = data.users.map(user => this.convUser_(user))
        this.csvUploadResults = data.results
        this.isUploadingCsv = false
        this.showCsvUploadCompleteModal = true
      } catch (err) {
        this.csvErrors = err.response.data
        this.isUploadingCsv = false
        this.showCsvUploadErrorModal = true
      }
    },
    clearErrors() {
      this.errors = {}
    },
    startEditEntry(entry) {
      this.clearErrors()

      // 編集用にコピーする
      entry.uid_edit = entry.uid
      entry.name_edit = entry.name
      entry.role_edit = entry.role
      entry.office_id_edit = entry.office_id
      entry.office_edit = entry.office
      entry.use_start_date_edit = entry.use_start_date
      entry.use_end_date_edit = entry.use_end_date
      entry.latest_transfer_date_edit = entry.latest_transfer_date
      entry.use_start_date_input_edit = entry.use_start_date_input
      entry.use_end_date_input_edit = entry.use_end_date_input
      entry.latest_transfer_date_input_edit = entry.latest_transfer_date_input
      entry.email_edit = entry.email
      entry.mfa_edit = entry.mfa ?? ''

      entry.isEditMode = true
    },
    saveEntry(entry) {
      let convertedEntry = this.convData(entry)
      if (!this.checkEntry(convertedEntry, false)) {
        return
      }
      userApi.update(convertedEntry)
        .then(({ data }) => {
          entry = Object.assign(entry, this.convUser_(data))
          this.blurActiveElement()
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
    },
    startEditPassword(entry) {
      this.editPasswordEntry = entry
    },
    hidePasswordDialog() {
      this.editPasswordEntry.editted_password = ''
      this.editPasswordEntry.editted_password_confirmation = ''
      this.editPasswordEntry = null
      this.clearErrors()
    },
    submitPassword() {
      this.clearErrors()
      const entry = this.editPasswordEntry
      if (!this.checkEntry(entry, true)) {
        return
      }
      userApi.update(entry)
        .then(() => {
          this.hidePasswordDialog()
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
    },
    endEditEntry(entry) {
      entry.isEditMode = false
      this.errors = {}
      // ボタンにフォーカス残ったままになるのが気になる場合があるので
      this.blurActiveElement()
    },
    confirmDeleteEntry(entry) {
      this.clearErrors()
      this.deleteEntry = entry
    },
    doDeleteEntry() {
      userApi.delete(this.deleteEntry)
        .then(() => {
          let idx = -1
          this.list.forEach((ent, i) => {
            if (ent.id === this.deleteEntry.id) {
              idx = i
            }
          })
          if (idx !== -1) {
            Vue.delete(this.list, idx)
          }
          this.deleteEntry = null
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
    },
    showAddEntryModal() {
      this.clearErrors()
      const dtToStr = (date) => {
        return Vue.filter('dtFormat')(date, 'yyyy-mm-dd')
      }
      this.createEntry = {
        use_start_date_input_edit: dtToStr(new Date()),
        use_end_date_input_edit: dtToStr(new Date(9999, 12 - 1, 31, 0, 0, 0)),
      }
    },
    dismissCreateModal() {
      this.clearErrors()
      this.createEntry = null
    },
    checkEntry(entry, isNeededPassword) {
      this.clearErrors()
      let ret = true
      if (!entry.uid) {
        this.errors['uid'] = ['ログインIDは必須項目です']
        ret = false
      } else if (entry.uid.length > 255) {
        this.errors['uid'] = ['ログインIDは225文字以内で入力して下さい']
        ret = false
      }
      if (!entry.name) {
        this.errors['name'] = ['名前は必須項目です']
        ret = false
      } else if (entry.name.length > 255) {
        this.errors['name'] = ['名前は225文字以内で入力して下さい']
        ret = false
      }
      if (!entry.office_id) {
        this.errors['office_id'] = ['事務所は必須項目です']
        ret = false
      }
      if (!entry.use_start_date) {
        this.errors['use_start_date'] = ['利用開始日は必須項目です']
        ret = false
      }
      if (!entry.use_end_date) {
        this.errors['use_end_date'] = ['利用終了日は必須項目です']
        ret = false
      }
      if (entry.mfa === MFA_EMAIL && !entry.email) {
        this.errors['email'] = ['メールアドレスは必須項目です']
        ret = false
      }
      if (entry.email && !this.validateEmail(entry.email)) {
        this.errors['email'] = ['有効なメールアドレスを指定してください。']
        ret = false
      }
      if (isNeededPassword) {
        if (!entry.editted_password) {
          this.errors['editted_password'] = ['パスワードは必須項目です']
          ret = false
        } else if (entry.editted_password.length < 8) {
          this.errors['editted_password'] = ['パスワードは8文字以上で設定して下さい']
          ret = false
        } else if (entry.editted_password !== entry.editted_password_confirmation) {
          this.errors['editted_password'] = ['パスワードとパスワード(確認)が一致していません']
          ret = false
        }
      }
      return ret
    },
    validateEmail(email) {
      const regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return regEmail.test(email)
    },
    labelRole(rolecode) {
      if (rolecode === 100 || rolecode === '100') {
        return '管理者'
      } else if (rolecode === 201 || rolecode === '201') {
        return '総括'
      } else if (rolecode === 200 || rolecode === '200') {
        return '主任'
      } else {
        return '作業者'
      }
    },
    convData(entry) {
      let convertedEntry = {
        'uid': entry.uid_edit,
        'name': entry.name_edit,
        'office_id': entry.office_id_edit,
        'use_end_date': entry.use_end_date_edit,
        'use_start_date': entry.use_start_date_edit,
        'latest_transfer_date': entry.latest_transfer_date_edit,
        'role': entry.role_edit,
        'area': entry.area_edit,
        'email': entry.email_edit,
        'mfa': entry.mfa_edit === '' ? null : entry.mfa_edit,
      }
      if ('editted_password' in entry) {
        convertedEntry.editted_password = entry.editted_password
      }
      if ('editted_password_confirmation' in entry) {
        convertedEntry.editted_password_confirmation = entry.editted_password_confirmation
      }
      if ('use_start_date_input_edit' in entry) {
        convertedEntry.use_start_date_input = entry.use_start_date_input_edit
        convertedEntry.use_end_date_input = entry.use_end_date_input_edit
        convertedEntry.latest_transfer_date_input = entry.latest_transfer_date_input_edit
        convertedEntry.use_start_date = entry.use_start_date_input_edit
        convertedEntry.use_end_date = entry.use_end_date_input_edit
        convertedEntry.latest_transfer_date = entry.latest_transfer_date_input_edit
      }
      for (let office in this.offices) {
        if (office.id === entry.office_id) {
          convertedEntry.office = office
          break
        }
      }
      if ('id' in entry) {
        convertedEntry.id = entry.id
      }
      return convertedEntry
    },
    doAddEntry() {
      let convertedEntry = this.convData(this.createEntry)
      if (!this.checkEntry(convertedEntry, true)) { return }

      userApi.create({'user': convertedEntry, 'editted_password': this.createEntry.editted_password})
        .then(({ data }) => {
          this.list.push(this.convUser_(data))
          this.createEntry = null
        })
        .catch(err => {
          this.handleErrorResponse(err)
        })
    },
    blurActiveElement() {
      window.setTimeout(() => {
        if ('activeElement' in document) {
          document.activeElement.blur()
        }
      }, 0)
    },
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
